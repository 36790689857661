import {
  getLandingpageName,
  getLandingpageSubpath,
} from "helpers/routing/utils";
import getConfig from "next/config";
import { NextRouter } from "next/router";
import { getCountryAndLanguage } from "../locale/utils";

/**
 * Gets a path from slug param.
 *
 * @param slug slug string array or string from params
 * @param leadingSlash whether to add leading slash to path
 * @returns string with slug as path, e. g. "company/events"; if slug is string it returns slug
 */
export const getPathFromSlug = (
  slug?: NextRouter["query"][string],
  leadingSlash = false,
): string => {
  if (!slug) return "";
  const path = Array.isArray(slug) ? slug.join("/") : slug?.replace(/^\//, "");
  return leadingSlash ? `/${path}` : path;
};

/**
 * Gets a path without leading or trailing slash to get same format as slug.
 *
 * @param path path string which could contain leading or trailing slash, e. g. "/company/events/"
 * @returns path string without leading or trailing slash, e. g. "company/events"
 */
export const getPathWithoutLeadingOrTrailingSlash = (path: string) =>
  path.replace(/^\/|\/$/g, "");

/**
 * Gets url at which the page with passed information is accessible.
 *
 * @param locale in format `${country}_${language}` e.g. "de_de"
 * @param slug as string, e. g. "company/events", or string array, e. g. ["company", "events"]
 * @returns url string with passed information, e. g. for production "https://de.krohne.com/de/company/events", for development "http://localhost:3000/de_de/company/events"
 */
export const generateUrl = (
  locale: string,
  slug: string | string[],
): string => {
  const [country, language] = getCountryAndLanguage(locale);
  const { publicRuntimeConfig } = getConfig();

  if (!country || !language || !publicRuntimeConfig) return "";

  const landingpageName = getLandingpageName(slug);
  const slugPath = landingpageName
    ? getLandingpageSubpath(slug)
    : getPathFromSlug(slug);
  const isLocalhost = publicRuntimeConfig.BASE_URL.includes("localhost");

  const protocol = `http${!isLocalhost ? "s" : ""}`;
  const landingpageSubdomain = landingpageName ? `${landingpageName}.` : "";
  const countrySubdomain =
    !isLocalhost && !["k", "lp", "cn"].includes(country) ? `${country}.` : "";
  const domain = landingpageName
    ? "krohne.com"
    : !isLocalhost && country === "cn"
    ? "krohnechina.com"
    : publicRuntimeConfig.BASE_URL === "krohnechina.com" && country !== "cn"
    ? "krohne.com"
    : publicRuntimeConfig.BASE_URL;
  const localeSegment = isLocalhost ? locale : language;
  const path = slugPath ? `${localeSegment}/${slugPath}` : localeSegment;

  return `${protocol}://${landingpageSubdomain}${countrySubdomain}${domain}/${path}`;
};

/**
 * Checks whether the short link is valid.
 *
 * @param shortLink String which should be tested
 * @param including if true function returns true even if a shortlink only is included in string, e.g. "foo... kp-123 bar"
 * @returns true if the passed entity matches the format `^([a-z]+)-([0-9]+)(-([a-z]+))?$`, e.g. "kp-123", "page-42-en", otherwise false
 */
export const isShortLink = (shortLink: string, including = false): boolean => {
  if (including) {
    return !!/([a-z]+)-([0-9]+)(-([a-z]+))?/gm.exec(shortLink);
  }
  return !!/^([a-z]+)-([0-9]+)(-([a-z]+))?$/g.exec(shortLink);
};

/**
 * Gets the segments of a path string.
 *
 * @param path string with slashes to separate segments
 * @returns array of segment strings
 */
export const getPathSegments = (path: string) =>
  path.split("/").filter((segment) => segment.trim());

/**
 * Checks whether the entity identifier is valid.
 *
 * @param entityId String which should be tested
 * @returns true if the passed entity matches the format `^[a-z]+-[0-9]+$`, e.g. "kp-123", otherwise false
 */
export const isEntityId = (entityId: string): boolean => {
  return !!/^[a-z]+-[0-9]+$/g.exec(entityId);
};

/**
 * Decomposes an entity id into its components, the type name and the number
 *
 * @param entityId in format `^[a-z]+-[0-9]+$`, e.g. "kp-123"
 * @returns components of the entity id as an array. The type as the first element and the number as the second. E.g. "kp-123" => ["kp", 123]. Falls back to empty array.
 */
export const getEntityTypeAndNumber = (
  entityId: string,
): [string, number] | [] => {
  if (!isEntityId(entityId)) return [];

  const [, type, numberString] = /^([a-z]+)-([0-9]+)$/g.exec(entityId);

  if (
    !type ||
    !numberString ||
    typeof type !== "string" ||
    typeof numberString !== "string"
  )
    return [];

  const number = parseInt(numberString, 10);

  if (isNaN(number)) return [];

  return [type, number];
};
