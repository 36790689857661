import { Col, Container, Nav, Row } from "react-bootstrap";
import { CustomerPortalButton } from "components/customer-portal";
import { FC, useEffect } from "react";
import { IconNavLink } from "components/icon-nav-link";
import { isLandingpage as checkLandingpage } from "helpers/routing/utils";
import { Media, MetaBarProps, MetaNavPage, PageProps } from "constants/types";
import { shameScroll } from "helpers/shame-scroll";
import { Shape } from "components/shape";
import { useLocale } from "helpers/locale";
import { useLocaleContext } from "contexts/locale-context";
import { useSlugPath } from "helpers/routing";
import classNames from "classnames";
import Link from "components/link";
import Router from "next/router";
import WebApps from "components/_layout/page/web-apps";

import LocaleSwitcher, {
  languageLabel,
} from "components/_layout/page/locale-switcher";

// we could possibily use IntlProvider,
// but it seems that its not returning a context for us?
// or we could use the router ...
// we could wrap it around all components, but that would rerender all, if state/context changes

const MetaNavigation: FC<MetaBarProps> = (props) => {
  const { language } = useLocale();
  const { localeOverlay, setLocaleOverlay } = useLocaleContext();
  const renderLinks: MetaNavPage[][] = props.links.reduce(
    (acc, link) => {
      if (!link.show_in_menu) return acc;

      // every link with icon gets filled in the second array, every without in first array
      acc[link?.icon ? 1 : 0].push(link);
      return acc;
    },
    [[], []],
  );

  const slugPath = useSlugPath();
  const isLandingpage = checkLandingpage(slugPath);
  const availableLanguagesAmount = Object.values(props?.paths || {}).filter(
    Boolean,
  ).length;
  const isGlobal =
    props.currentCountry?.id === "ws-1" ||
    props.currentCountry?.country?.[0]?.code === "lp";

  return (
    <div className="meta" id="meta">
      <div className="meta-bar">
        <Container fluid="xl">
          <Row className="justify-content-between">
            <Col>
              <WebApps apps={props.apps} webAppsLabel={props.webAppsLabel} />

              {(!isLandingpage || availableLanguagesAmount > 1) && (
                <Nav
                  className="d-inline-flex"
                  onClick={() => {
                    setLocaleOverlay(!localeOverlay);
                  }}
                >
                  <IconNavLink
                    className="language-icon"
                    icon={isGlobal ? "globe" : null}
                    media={props.currentCountry?.key_visual?.[0] as Media}
                    open={localeOverlay}
                    type={isGlobal ? null : "media"}
                    label={`${!isLandingpage ? `${props.title} / ` : ""}${
                      languageLabel[language]
                    }`}
                  />
                </Nav>
              )}
            </Col>
            <Col>
              <Nav className="meta-right justify-content-end">
                {renderLinks &&
                  renderLinks.map((items, itemsIndex) =>
                    items.map((item, index) =>
                      itemsIndex <= 0 ? (
                        item.url && (
                          <Nav.Item key={item.id}>
                            <Link
                              href={item.url}
                              passHref={true}
                              prefetch={false}
                              className="nav-link"
                              {...(item.external ? { target: "_blank" } : {})}
                            >
                              {item.navigation_title ?? item.title}
                            </Link>
                          </Nav.Item>
                        )
                      ) : (
                        <div
                          className={classNames(
                            "nav-item highlight",
                            index === 0 && "first",
                          )}
                          key={item.id}
                        >
                          <Link
                            href={item.url}
                            passHref={true}
                            prefetch={false}
                            className="btn btn-sm nav-link"
                            {...(item.external ? { target: "_blank" } : {})}
                          >
                            <>
                              <Shape variant={item.icon} />
                              <div>{item.navigation_title ?? item.title}</div>
                            </>
                          </Link>
                        </div>
                      ),
                    ),
                  )}
                <CustomerPortalButton className="nav-item highlight" />
              </Nav>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

interface MetaNavWrapperProps {
  entityId: string;
  header: PageProps["header"];
}

const MetaNavWrapper: FC<MetaNavWrapperProps> = ({ header, entityId }) => {
  const { setLocaleOverlay, localeOverlay } = useLocaleContext();

  useEffect(() => {
    const handleRouteChangeStart = (url, options) => {
      if (options?.shallow || options?.scroll === false || !localeOverlay)
        return;
      setLocaleOverlay(false);
      shameScroll({ close: true });
    };
    Router.events.on("routeChangeStart", handleRouteChangeStart);

    return () => {
      Router.events.off("routeChangeStart", handleRouteChangeStart);
    };
  }, [localeOverlay, setLocaleOverlay]);
  // Use dom events to set localeoverlay because localecontext is not available in normal navigation component
  // TODO: maybe find a better solution for this
  useEffect(() => {
    const localeSwitcher = document.querySelector(".open-locale-switcher");

    const handleLocaleSwitcherClick = () => {
      setLocaleOverlay((prevState) => !prevState);
    };

    if (localeSwitcher) {
      localeSwitcher.addEventListener("click", handleLocaleSwitcherClick);
    }

    return () => {
      if (localeSwitcher) {
        localeSwitcher.removeEventListener("click", handleLocaleSwitcherClick);
      }
    };
  });

  return (
    <>
      <LocaleSwitcher
        breadcrumb={header?.breadcrumb}
        currentCountry={header?.base?.currentCountry}
        entityId={entityId}
      />
      <MetaNavigation
        links={header?.meta ?? []}
        title={header?.base?.title ?? ""}
        languages={header?.base?.available_language ?? []}
        currentCountry={header?.base?.currentCountry}
        webAppsLabel={header?.base?.web_apps_label?.label ?? ""}
        apps={header?.base?.web_apps ?? []}
        paths={header?.paths ?? {}}
      />
    </>
  );
};

export default MetaNavWrapper;
