import { BreadcrumbNav, SelectOption } from "constants/types";
import { breakpoints } from "theme/theme";
import { CountriesResponse } from "pages/api/countries";
import { isLandingpage } from "helpers/routing/utils";
import { kebabCase } from "lodash";
import { LocationDataResponse } from "pages/api/locationdata";
import { Select } from "components/forms/select";
import { Tabs } from "components/tabs";
import { useCachedFetch } from "helpers/hooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useSlugPath } from "helpers/routing";
import { Ws } from "types";
import LanguageCard from "./language-card";
import LanguageContent from "./language-content";

type LanguagesProps = {
  breadcrumb: BreadcrumbNav;
  currentCountry: Ws;
  entityId: string;
};

const Languages = ({
  breadcrumb,
  currentCountry,
  entityId,
}: LanguagesProps) => {
  const [currentRegion, setCurrentRegion] = useState(
    currentCountry.regions?.[0]?.code,
  );

  const [locationData]: [LocationDataResponse, (_) => void] = useCachedFetch(
    kebabCase("/api/locationdata"),
    () => fetch("/api/locationdata").then((res) => res.json()),
    // 3 month
    60 * 60 * 24 * 30 * 3,
  );

  const isMobile = useMediaQuery({
    query: `(max-width: ${breakpoints.sm - 1}px)`,
  });

  const slugPath = useSlugPath();
  const isLandingPage = isLandingpage(slugPath);

  const [countries, setCountries] = useState<CountriesResponse | null>(null);

  useEffect(() => {
    fetch(`/api/countries?entityId=${entityId}`)
      .then((res) => res.json())
      .then(setCountries);
  }, [entityId]);

  const title = breadcrumb?.find((x) => x?.title !== undefined)?.title;

  const regionFilter = useMemo(
    () =>
      countries?.filters.find(
        (filter) => filter.filter_attribute === "regions",
      ),
    [countries],
  );

  const regionOptions = useMemo(
    () =>
      regionFilter?.options
        .reduce(function (array: SelectOption[], option) {
          if (option.code === "group") {
            return array;
          }

          return array.concat({
            label: option.label,
            value: option.code,
          });
        }, [])
        ?.sort(
          (a, b) =>
            (b.value === currentRegion ? 1 : 0) -
            (a.value === currentRegion ? 1 : 0),
        ),
    [regionFilter, currentRegion],
  );

  const [internalActiveTab, setInternalActiveTab] = useState(0);
  const activeTab = regionOptions?.[internalActiveTab]?.value;
  const setActiveTab = useCallback(
    (value) => {
      setInternalActiveTab(regionOptions?.findIndex((x) => x.value === value));
    },
    [regionOptions],
  );

  const entities = countries?.entities.filter(
    (entity) => entity.regions?.[0]?.code === activeTab,
  );

  const globalEntity = countries?.entities[0];

  useEffect(() => {
    let nextCurrentRegion = "europe";

    if (locationData?.country_code) {
      const country = countries?.entities.find(
        (x) => x.country?.[0].code === locationData.country_code,
      );

      if (country) {
        nextCurrentRegion = country.regions?.[0]?.code;
      }
    } else {
      nextCurrentRegion = currentCountry.regions?.[0]?.code ?? "europe";
    }

    setCurrentRegion(nextCurrentRegion);
    setActiveTab(nextCurrentRegion);
  }, [countries]);

  const landingPageEntity = countries?.entities.find(
    (x) => x.country?.[0].code === "lp",
  );

  return (
    <div className="language-container">
      {isLandingPage ? (
        <>
          <LanguageCard
            entity={landingPageEntity ?? currentCountry}
            title={title}
          />
        </>
      ) : isMobile ? (
        <>
          <Select
            className="show-indicator"
            value={regionOptions?.find((x) => x.value === activeTab)}
            options={regionOptions}
            handleChange={(option) => setActiveTab(option.value)}
            isClearable={false}
          />

          <hr className="language-container__divider" />

          <LanguageContent entities={entities} globalEntity={globalEntity} />
        </>
      ) : (
        <Tabs
          activeTabIndex={internalActiveTab}
          onSelect={(key) => setInternalActiveTab(key)}
          tabs={regionOptions?.map((regionOption) => ({
            title: regionOption.label,
            component: (
              <LanguageContent
                entities={entities}
                globalEntity={globalEntity}
              />
            ),
          }))}
        />
      )}
    </div>
  );
};

export default Languages;
