/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @next/next/no-img-element */
import { colors } from "../../../theme/theme";
import { Ct, Li } from "types";
import { Fragment } from "react";
import { getAppInfo } from "helpers/utils";
import { Icon, Shape } from "components/shape";
import { IconNavLink } from "components/icon-nav-link";
import { isUserCentricsAnchorLink } from "./tracker";
import { languageLabel } from "components/_layout/page/locale-switcher";
import { Newsletter } from "components/_newsletter";
import { useLabels } from "helpers/hooks";
import { useLocale } from "helpers/locale";
import { useLocaleContext } from "contexts/locale-context";
import { useSlugPath } from "helpers/routing";
import Link from "components/link";
import Logo from "./logo";
import nav_settings from "config/navigation";

import {
  GlobalBase,
  Link as LinkType,
  Media,
  MetaNav,
  PageTree,
} from "constants/types";

import {
  Accordion,
  Button,
  Card,
  Col,
  Container,
  Nav,
  Row,
} from "react-bootstrap";

const Footer = ({
  contact,
  contacts,
  globalBase,
  isLandingpage,
  meta,
  nav,
  related_links,
}: {
  contact: Ct;
  contacts: MetaNav;
  globalBase: GlobalBase;
  isLandingpage: boolean;
  meta: MetaNav;
  nav: PageTree;
  related_links: Li[];
}) => {
  const { language } = useLocale();
  const { version } = getAppInfo();

  const { localeOverlay, setLocaleOverlay } = useLocaleContext();

  const path = useSlugPath();
  const [contactBtn] = useLabels(["ui-313", "Contact"]);
  const sections: PageTree[] =
    nav && nav.children
      ? Object.values(nav.children).filter(
          (pob) =>
            !["page-143", ...Object.values(nav_settings)].some(
              (page) => pob.id === page,
            ) && pob.show_in_menu === true,
        )
      : [];

  // abused the tb field subtitle for the newsletter path
  const newsletterSubtitle: string =
    globalBase?.newsletter?.tb?.subtitle || "/newsletter";
  const newsletterPath =
    newsletterSubtitle.charAt(0) === "/"
      ? newsletterSubtitle
      : `/${newsletterSubtitle}`;

  const isGlobal =
    globalBase?.currentCountry?.id === "ws-1" ||
    globalBase?.currentCountry?.country?.[0]?.code === "lp";

  return (
    <>
      {isLandingpage ? null : (
        <Newsletter newsletter={globalBase?.newsletter} />
      )}
      <footer
        className={
          path === newsletterPath ? "mt-5" : isLandingpage ? "p-0" : ""
        }
      >
        {isLandingpage ? null : (
          <div className="sitemap d-none d-md-block hyphenate" key="sitemap">
            <Container>
              <Row className="">
                {contact ? (
                  <Col className="col-xl-2 col-lg-6 col-md-6 col-12 sitemapItem">
                    <p className="h6">{contactBtn.label}</p>
                    <p>
                      <strong>{contact.title}</strong>
                      <br />
                      {contact.street_address
                        ? contact.street_address.split("\n")?.map((line) => (
                            <Fragment key={line}>
                              <span>{line}</span>
                              <br />
                            </Fragment>
                          ))
                        : null}
                    </p>
                    <hr
                      style={{
                        margin: "20px 0 21px 0",
                      }}
                    />
                    <nav className="nav justify-content-center flex-column">
                      {contacts &&
                        contacts.map((item) =>
                          item.url ? (
                            <Nav.Item key={item.id}>
                              <Link
                                href={item.url}
                                passHref
                                prefetch={false}
                                legacyBehavior
                              >
                                <Nav.Link>{item.title}</Nav.Link>
                              </Link>
                            </Nav.Item>
                          ) : null,
                        )}
                    </nav>
                  </Col>
                ) : null}
                {sections &&
                  sections.map((section, index) => (
                    <Fragment key={section.id}>
                      {index === 5 ? (
                        <Col className="col-xl-2 d-none d-xl-flex sitemapItem"></Col>
                      ) : null}
                      <Col className="col-xl-2 col-lg-6 col-md-6 col-12 sitemapItem">
                        {section.url ? (
                          <Nav.Item>
                            <Link
                              href={section.url}
                              passHref
                              rel="nofollow"
                              prefetch={false}
                            >
                              <p className="h6">{section.title}</p>
                            </Link>
                          </Nav.Item>
                        ) : null}
                        <nav className="nav flex-column">
                          {section &&
                            section.children &&
                            Object.values(section.children)?.map((child) =>
                              child.show_in_menu && child.url ? (
                                child.external ? (
                                  <a
                                    href={child.url}
                                    target="_blank"
                                    className="nav-link"
                                    rel="noreferrer"
                                    key={child.id}
                                  >
                                    {child.navigation_title ?? child.title}
                                  </a>
                                ) : (
                                  <Nav.Item key={child.id}>
                                    <Link
                                      href={child.url}
                                      passHref
                                      prefetch={false}
                                      legacyBehavior
                                    >
                                      <Nav.Link rel="nofollow">
                                        {child.navigation_title ?? child.title}
                                      </Nav.Link>
                                    </Link>
                                  </Nav.Item>
                                )
                              ) : null,
                            )}
                        </nav>
                      </Col>
                    </Fragment>
                  ))}
              </Row>
            </Container>
          </div>
        )}

        {contact && !isLandingpage ? (
          <Container key="contact-container" className="d-md-none">
            <Row>
              <Col xs={12}>
                <Card>
                  <Card.Body>
                    <p>
                      <strong>{contact.title}</strong>
                      <br />
                      {contact.street_address
                        ? contact.street_address.split("\n")?.map((line) => (
                            <Fragment key={line}>
                              <span>{line}</span>
                              <br />
                            </Fragment>
                          ))
                        : null}
                    </p>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        ) : null}
        {!isLandingpage ? (
          <Accordion className="w-100 d-md-none" key="accordion">
            {sections &&
              contacts &&
              ([...sections, ...contacts] as (PageTree | LinkType)[])?.map(
                (section, index) => {
                  return (
                    <Card key={index + 1} className="">
                      <Card.Header className="bg-white p-0">
                        {!section.show_in_menu ? (
                          <Nav.Item>
                            <Link
                              href={section.url}
                              passHref
                              prefetch={false}
                              className="p-0 w-100 btn btn-link"
                            >
                              <Container>
                                <Row>
                                  <Col className="d-flex col-12 justify-content-between align-content-center align-items-center">
                                    <p>{section.title}</p>
                                    {/* <Shape
                                      variant={"caret-right"}
                                      fill={colors.gray40}
                                    /> */}
                                  </Col>
                                </Row>
                              </Container>
                            </Link>
                          </Nav.Item>
                        ) : (
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey={(index + 1).toString()}
                            className="p-0 w-100"
                            onClick={(e) => {
                              const target = e.target as HTMLElement;
                              if (target?.classList.contains("opened")) {
                                target?.classList.remove("opened");
                              } else {
                                if (
                                  document
                                    .getElementsByTagName("footer")[0]
                                    .getElementsByClassName("opened").length
                                ) {
                                  document
                                    .getElementsByTagName("footer")[0]
                                    .getElementsByClassName("opened")[0]
                                    ?.classList.remove("opened");
                                }
                                target?.classList.add("opened");
                              }
                            }}
                          >
                            <Container>
                              <Row>
                                <Col className="d-flex col-12 justify-content-between align-content-center align-items-center">
                                  <p>{section.title}</p>
                                  <Shape
                                    size={10}
                                    variant={"caret-down"}
                                    fill={colors.gray40}
                                  />
                                </Col>
                              </Row>
                            </Container>
                          </Accordion.Toggle>
                        )}
                      </Card.Header>

                      <Accordion.Collapse eventKey={(index + 1).toString()}>
                        <Card.Body>
                          <Container>
                            <Row>
                              <Col xs={12}>
                                {section.url ? (
                                  <Nav.Item>
                                    <Link
                                      href={section.url}
                                      passHref
                                      prefetch={false}
                                    >
                                      {section.title}
                                    </Link>
                                  </Nav.Item>
                                ) : null}
                                <nav className="nav flex-column">
                                  {section &&
                                    "children" in section &&
                                    section.children &&
                                    Object.values(section.children)?.map(
                                      (child) =>
                                        child.show_in_menu && child.url ? (
                                          child.external ? (
                                            <a
                                              href={child.url}
                                              target="_blank"
                                              className="nav-link"
                                              rel="noreferrer"
                                              key={child.id}
                                            >
                                              {child.navigation_title ??
                                                child.title}
                                            </a>
                                          ) : (
                                            <Nav.Item
                                              key={child.id}
                                              className="mt-1"
                                            >
                                              <Link
                                                href={child.url}
                                                passHref
                                                prefetch={false}
                                              >
                                                {child.navigation_title ??
                                                  child.title}
                                              </Link>
                                            </Nav.Item>
                                          )
                                        ) : null,
                                    )}
                                </nav>
                              </Col>
                            </Row>
                          </Container>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  );
                },
              )}
          </Accordion>
        ) : null}

        <div className="copyright copyright__highlight">
          <Container>
            <div className="flex-between-center gap-y-3">
              <Nav
                className="d-inline-flex"
                onClick={() => {
                  setLocaleOverlay(!localeOverlay);
                }}
              >
                <IconNavLink
                  className="language-icon text-white"
                  icon={isGlobal ? "globe" : null}
                  open={localeOverlay}
                  type={isGlobal ? null : "media"}
                  media={globalBase?.currentCountry?.key_visual?.[0] as Media}
                  label={`${!isLandingpage ? `${globalBase?.title} / ` : ""}${
                    languageLabel[language]
                  }`}
                />
              </Nav>
              <div className="flex-center">
                <nav className="nav align-items-center gap-x-4 gap-y-1">
                  {meta &&
                    meta.map((item) =>
                      item.url ? (
                        <Nav.Item key={item.id}>
                          {isUserCentricsAnchorLink(item.url) ? (
                            <Nav.Link href={item.url}>{item.title}</Nav.Link>
                          ) : (
                            <Link
                              href={item.url}
                              passHref
                              prefetch={false}
                              legacyBehavior
                            >
                              <Nav.Link
                                rel={`${
                                  item.url !== "/other-krohne-websites"
                                    ? "nofollow"
                                    : ""
                                }`}
                              >
                                {item.title}
                              </Nav.Link>
                            </Link>
                          )}
                        </Nav.Item>
                      ) : null,
                    )}
                </nav>
                <p className="w-0 h-0" style={{
                  color: "#2885d0"
                }}>v{version}</p>
              </div>
            </div>
          </Container>
        </div>

        <div className="copyright">
          <Container>
            <div className="flex-between-center">
              <Link className="grow" href="/" passHref prefetch={false}>
                <Logo />
              </Link>
              <div className="footer__related-links">
                {related_links?.map((link) => {
                  if (link.key_visual) {
                    const url = new URL(
                      // @ts-ignore
                      link.key_visual?.[0].link_absolute_path as string,
                    );
                    const [, icon] = /\/([a-z_]+).([a-z]+)$/gm.exec(
                      url?.pathname,
                    );

                    return (
                      <a
                        href={link.link_absolute_path}
                        target="_blank"
                        rel="nofollow noreferrer"
                        key={url?.pathname}
                      >
                        <Shape variant={icon as Icon} />
                      </a>
                    );
                  } else return null;
                })}
              </div>
            </div>
            {globalBase?.footer_text && (
              <p
                className="copyright-text"
                dangerouslySetInnerHTML={{
                  __html: String(globalBase?.footer_text),
                }}
              ></p>
            )}
          </Container>
        </div>
      </footer>
    </>
  );
};

export default Footer;
