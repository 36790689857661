import classNames from "classnames";
import { ShapeProps } from "constants/types";
import { isShortLink } from "helpers/data/utils";
import { useLocale } from "helpers/locale";
import { generateRoute, useSlugPath } from "helpers/routing";
import { isQueryOrHash } from "helpers/routing/utils";
import NextLink from "next/link";
import { ComponentProps, FC } from "react";
import { Shape } from "../shape";

/**
 * Should be used instead of Next.js Link (`next/link`) component to use our locale handling
 */
const ForceLocaleLink: FC<ComponentProps<typeof NextLink>> = (props) => {
  const { locale: routerLocale } = useLocale();
  const path = useSlugPath();

  const hrefProp =
    typeof props.href === "object" ? props.href?.toString() : props.href;
  const locale = props.locale || routerLocale;
  const urlWithDomainPattern =
    /^(?:https?:\/\/)?([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(?:\/.*)?$/;
  const isUrlWithDomain = urlWithDomainPattern.test(hrefProp);

  const href = !hrefProp
    ? generateRoute(locale, "", routerLocale, path)
    : isUrlWithDomain || isShortLink(hrefProp)
    ? hrefProp
    : generateRoute(locale, hrefProp, routerLocale, path);

  return (
    <NextLink
      {...props}
      href={href}
      locale={false}
      shallow={
        isQueryOrHash(hrefProp) ||
        isQueryOrHash(hrefProp?.replace(path, "")?.replace(/\//, "")) ||
        props.shallow
      }
    />
  );
};

interface LinkProps {
  className?: string;
  href: string;
  icon?: ShapeProps;
  isAnchor?: boolean;
  label: string;
  locale?: string;
  passHref?: boolean;
}

export const Link = (props: LinkProps) =>
  props.isAnchor ? (
    <a
      href={props.href}
      className={classNames("link flex-start-center", props.className)}
    >
      {!!props.icon && <Shape {...props.icon} />}
      <span className="d-block label">{props.label}</span>
    </a>
  ) : (
    <ForceLocaleLink
      href={props.href}
      passHref={props.passHref}
      className={classNames("link flex-start-center", props.className)}
    >
      {!!props.icon && <Shape {...props.icon} />}
      <span className="d-block label">{props.label}</span>
    </ForceLocaleLink>
  );

export default ForceLocaleLink;
